import React, {useEffect, useMemo, useState} from "react";

export const Warning = (props) => {


    const [message,setMessage]  = useState("")
    const msg = useMemo(()=>props.message,[props])

    useEffect(()=>{
        setMessage(msg)
    },[msg])


    const hideMe = ()=>{
        setMessage([])
    }

    if(!message || message.length === 0) return null;

        return (<div className={"warning"} onClick={hideMe}><div><div className={"warning__sign"}>&#9888;</div><div><span>{message}</span></div></div></div>)


}
