import React, {useEffect, useRef, useState} from "react";
import {Warning} from "./Warning";

import {logout, useTracked, verifyPin} from "../store";
import PinInput from 'react-pin-input';
import {Loader} from "./Loader";
import {useTranslation} from "react-i18next";

export function VerifyPin() {

    const { t } = useTranslation();
    const pin = useRef()
    const [state, dispatch] = useTracked();
    const [warning, setWarning] = useState([])


    useEffect(() => {
        if (state.pinRetries === 0) {
            setWarning([])
            dispatch(logout())
        }

        if (state.pinRetries) {
            setWarning([t('warningPinWrong')]);
        }

    }, [state.pinRetries,dispatch,t])


    const handlePin = (pin) => {

        if (pin !== "") {
            dispatch(verifyPin(pin))
        }

    }


    if (!state.hasVerifiedPasscode || !state.isLoggedIn) {
        return null
    }

    if (state.hasVerifiedPin) {
        return null
    }

    if (state.loading) {
        return <Loader/>
    }


    return (
        <>
            <Warning message={warning}/>
            <p>{t('smsText')}</p>
            <PinInput
                length={4}
                initialValue=""
                focus
                onChange={(value, index) => {
                    setWarning([])
                }}
                ref={pin}
                type="numeric"
                style={{marginTop: 5}}
                inputStyle={{
                    backgroundColor: 'white',
                    borderColor: "gray",
                    borderRadius: 10,
                    border: 2,
                    borderStyle: "solid"
                }}
                inputFocusStyle={{borderColor: '#444'}}
                onComplete={(value, index) => {
                    pin.current.clear()
                    handlePin(value)
                }}
            />


        </>
    )

}
