import React from "react";

import {forgetMe, useTracked} from "../store";
import {Loader} from "./Loader";
import {Trans, useTranslation} from "react-i18next";


export function RegisterFailure() {
    const { t } = useTranslation();
    const [state, dispatch] = useTracked();

    const handleLogout = () => {
        dispatch(forgetMe())
    }

    if (state.loading) {
        return <Loader/>
    }

    return (
        <>
            <Trans i18nKey="registerAlreadyRegistered"/>
            <button className={"primary"} href="#" onClick={() => handleLogout()}>{t('registerCancel')}</button>
        </>
    )

}
