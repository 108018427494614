import React from "react";

export const Timer = ({timestamp})=> {


    const zeroPad = (num, places) => String(num).padStart(places, '0')


    var seconds = Math.floor( (timestamp/1000) % 60 );
    var minutes = Math.floor( (timestamp/1000/60) % 60 );
    var hours = Math.floor( (timestamp/(1000*60*60)) % 24 );

    let tid =  (<span>{zeroPad(hours,2)}:{zeroPad(minutes,2)}:{zeroPad(seconds,2)}</span>)

    return tid

}