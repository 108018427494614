import React, {useEffect, useRef, useState} from "react";
import {getStreamInfo, logout, useTracked} from "../store";
import * as Sentry from '@sentry/browser'
import {useTranslation} from "react-i18next";
import {AudioPlayer} from "./AudioPlayer";
import {ReactComponent as Play} from "../image/play.svg"
import {ReactComponent as Stop} from "../image/stop.svg"
import {Warning} from "./Warning";
import {Timer} from "./Timer";
import {BeatLoader} from "react-spinners";
import {AudioFailed} from "./AudioFailed";
import {CastButton, useCast, useCastPlayer} from 'react-cast-sender';

export const Stream = () => {
    const {t, i18n} = useTranslation();
    const [play, setPlay] = useState(false);
    const [fail, setFail] = useState(false);
    //  const [url, setUrl] = useState(null);

    const {initialized, connected, deviceName,player} = useCast();

    const [warning, setWarning] = useState([]);
    const [chromecast, showChromecast] = useState(false);
    const [timer, setTimer] = useState(0);
    const [audioRef, setAudioRef] = useState(null);
    const a = useRef()

    const {
        loadMedia,
        currentTime,
        duration,
        isPaused,
        isMediaLoaded,
        togglePlay,
        seek,
        isMuted,
        tracks,
        editTracks,
        thumbnail,
        title,
        setVolume,
        toggleMute
    } = useCastPlayer();
    const [state, dispatch] = useTracked();


    useEffect(() => {
        dispatch(getStreamInfo())
    }, [])

    useEffect(() => {

            //setUrl('/api/stream.mp3?' + Date.now())

            if (play) {
                dispatch(getStreamInfo())
                setFail(false)
                setWarning([]);
                setTimer(0)

                if (connected) {

                    castStream()
                }

            } else {

            }

        }

        , [play]
    )


    useEffect(() => {

        if (play) {
            castStream()
        }

    }, [connected])


    const onFailure = () => {
        Sentry.captureException("Audio Failed, User is notified");
        //setPlay(false)
        //setTimer(0)
        setFail(true)

    }

    const audioFailedConfirmed = () => {
        Sentry.captureException("Audio Failed, User confirmed");
        setFail(false)

        setPlay(false)
        setTimer(0)


        setPlay(true)

    }

    const audioFailedClosed = () => {
        //setPlay(false)
        Sentry.captureException("Audio Failed, User declined");
        setFail(false)
    }

    const handlePlay = () => {
        console.log("User pressed play")
        setPlay(true)

    }

    const handleStop = () => {

        console.log("User pressed stop")
        if (connected) {
            console.log("stopping chromecast stop")
            new window.chrome.cast.media.StopRequest()

        }

        setPlay(false)

    }
    const handleLogout = () => {
        console.log("User logged out")
        dispatch(logout())
    }

    const castStream = async () => {

        const response = await fetch("/api/token")
        const token = await response.json()


        console.log("cast stream")
        const mediaInfo = new window.chrome.cast.media.MediaInfo(
            window.location.protocol + "//" + window.location.host + "/api/stream.mp3?token=" + token,
            'audio/mpeg'
        );

        mediaInfo.streamType = "LIVE"

        const metadata = new window.chrome.cast.media.GenericMediaMetadata();
        metadata.title = 'Samlingstelefonen';
        mediaInfo.metadata = metadata;

        const request = new window.chrome.cast.media.LoadRequest(
            mediaInfo
        );
        loadMedia(request);


    }


    //<audio is a IOS hack/>

    return (
        <>
            {fail && <AudioFailed onConfirm={audioFailedConfirmed} onClose={audioFailedClosed}/>}
            <Warning message={warning}/>
            <p>{t('streamText')}</p>
            <div className={"title"}>
                <h1>{state.transferConference || state.conference}</h1>
                <h2>{play ? <Timer timestamp={timer}/> : <br/>}</h2>
            </div>

            <audio ref={c => setAudioRef(c)}/>
            {

                !connected && play && (<AudioPlayer player={audioRef} onFailure={onFailure}
                                                    onPlayTimerUpdate={(timestamp) => setTimer(timestamp)}/>
                )
            }
            {state.loading !== true ?

                (play === true ?
                        (<button className={"primary"}
                                 onClick={handleStop}><Stop fill={"#ffffff"}/> {t('streamStopButton')}</button>) :
                        (<button className={"primary"}
                                 onClick={handlePlay}><Play fill={"#ffffff"}/> {t('streamStartButton')}</button>)
                ) :
                (<button className={"primary"} disabled><BeatLoader color={"white"}/></button>)}
            <div style={{position: "absolute", top: "10px", right: "10px"}}>
            <button style={{border: "none", backgroundColor: "transparent",fontSize:"25px"}} href="#" onClick={() => handleLogout()}>×</button>
            </div>
            <div className={"bottom"}>


                <CastButton/>
            </div>

        </>
    )


}
