import React from 'react'
import {useTranslation} from "react-i18next";
import {showUsageTerms, useTracked} from "../store";

export const UsageTerms = () => {
    const {t} = useTranslation();
    const [dispatch] = useTracked();
    const close = () => {
        dispatch(showUsageTerms(false));
    }

    return (
        <>
            <div className={"usageContainer"}>
                <div className={"usage"}>
                    <h2>Bruk av Samlingstelefonen</h2>
                    <h3>Eier og formål</h3>
                    <p>
                        Samlingstelefonen er er en tjeneste som eies og drives av Fellesskapet i Ofoten og Lofoten, Den
                        læstadianske forsamling, Norge.</p>

                    <p> Samlingstelefonen tilbyr lydoverføring av samlinger som avholdes. Retningslinjene sier at
                        samlingen bør komme fra det sted der lytteren vanligvis deltar på søndagssamling eller fra
                        nærmeste
                        sted med søndagssamling. Man skal ikke være registrert i en forsamling man ikke har
                        tilknytning til. Disse retningslinjene skal være kunngjort i hver forsamling som benytter
                        Samlingstelefonen.
                    </p>
                    <p>
                        Tilgang til tjenesten gis av forsamlingen du normalt deltar på søndagssamling i.
                        Under særlige hendelser gis brukere mulighet for å registrere seg selv.
                    </p>
                    <h3>Brukers forpliktelser</h3>
                    <p>Videredistribusjon og opptak av lyd er ikke tillat. Engangskode er personlig og videreformidling
                        er
                        ikke tillatt. Passord skal ikke gjøres tilgjengelig for uvedkommende, det vil si utenfor din
                        forsamling eller til tredjeparter.
                        Mangel på etterlevelse kan medføre at du mister tilgang til tjenesten.</p>
                    <h3>Logging</h3>
                    <p>
                        Brukere av Samlingstelefonen må akseptere at navn, telefonnummer og bruk av tjenesten logges. Dette er for å kunne gi
                        teknisk støtte til brukere som opplever problemer, for retting av tekniske problemer, og
                        for å måle kvalitet på tjenesten som tilbys.
                    </p>

                    <p>
                        Kun administratorer for Samlingstelefonen har tilgang til logger.
                        Dette gir administratorer i din forsamling mulighet til å gi støtte ved tekniske problemer og
                        for å håndheve retningslinjene for bruk av Samlingstelefonen.
                    </p>

                    <p>
                        Brukslogger oppbevares inntil 6 måneder, men kan bli slettet tidligere. Opplysninger som er
                        gitt under selvregistrering
                        (navn og telefonnummer) blir slettet når tjenesten stenger eller etter eget ønske. For å bli
                        slettet må du ta kontakt med administrator for Samlingstelefonen i din forsamling.
                    </p>
                </div>
                <button className="primary" onClick={close}>{t('close')}</button>
            </div>


        </>
    )
}
