import useThunkReducer from "react-hook-thunk-reducer";
import {createContainer} from "react-tracked";

const LOADING = Symbol('LOADING')
const CONFERENCE_LIST = Symbol("CONFERENCE_LIST")
const LOGIN = Symbol("LOGIN")
const LOGOUT = Symbol("LOGOUT")
const VERIFIED_PIN = Symbol("VERIFIED_PIN")
const VERIFIED_PIN_RETRIES = Symbol("VERIFIED_PIN_RETRIES")
const VERIFIED_PASSCODE = Symbol("VERIFIED_PASSCODE")
const VERIFIED_PASSCODE_RETRIES = Symbol("VERIFIED_PASSCODE_RETRIES")
const STREAM_INFO = Symbol("STREAM_INFO")
const SELF_REGISTER_OPEN = Symbol("SELF_REGISTER_OPEN")
const REGISTER_COMPLETE = Symbol("REGISTER_COMPLETE")
const REGISTER_FAILURE = Symbol("REGISTER_CONFLICT")
const SET_LANGUAGE = Symbol("SET_LANGUAGE")
const SHOW_USAGE_TERMS = Symbol("SHOW_USAGE_TERMS")
const FORGET_ME = Symbol("FORGET")
const SELF_REGISTER_NO_ACCESS = Symbol("SELF_REGISTER_NO_ACCESS")


const initialState = {
    loading: false,
    conferenceList: [],
    isLoggedIn: false,
    hasVerifiedPin: false,
    hasVerifiedPasscode: false,
    phoneNumber: "",
    conference: "",
    transferConference: "",
    passcodeRetries: null,
    pinRetries: null,
    selfRegisterOpen: false,
    profileCompleted: false,
    registerFailure: false,
    language: "NO",
    showUsageTerms: false,
    selfRegisterNoAccess: false,
};

const reducer = (state, action) => {

    switch (action.type) {
        case SHOW_USAGE_TERMS:
            return {...state, showUsageTerms: action.showUsageTerms}
        case LOADING:
            return {...state, loading: true}
        case CONFERENCE_LIST:
            return {...state, loading: false, conferenceList: action.conferenceList}
        case LOGIN:
            return {
                ...state,
                loading: false,
                isLoggedIn: action.isLoggedIn,
                hasVerifiedPin: action.pinVerified,
                hasVerifiedPasscode: action.passcodeVerified,
                profileCompleted: action.profileCompleted,
            }
        case VERIFIED_PASSCODE:
            return {...state, loading: false, hasVerifiedPasscode: action.passcodeVerified}
        case VERIFIED_PASSCODE_RETRIES:
            return {...state, loading: false, passcodeRetries: action.retries}
        case VERIFIED_PIN:
            return {...state, loading: false, hasVerifiedPin: action.pinVerified, profileCompleted: action.profileCompleted }
        case VERIFIED_PIN_RETRIES:
            return {...state, loading: false, pinRetries: action.retries}
        case LOGOUT:
            return {...initialState, conferenceList: state.conferenceList}
        case STREAM_INFO:
            return {
                ...state,
                loading: false,
                conference: action.streamInfo.conference,
                transferConference: action.streamInfo.transfer_conference
            }
        case SELF_REGISTER_OPEN:
            return {...state, selfRegisterOpen: action.selfRegisterOpen}
        case REGISTER_COMPLETE:
            return {...state, profileCompleted: action.profileCompleted}
        case REGISTER_FAILURE:
            return {...state, loading: false, registerFailure: true}
        case FORGET_ME:
            return initialState
        case SET_LANGUAGE:
            return {...state, language: action.language}
        case SELF_REGISTER_NO_ACCESS:
            return {...state, selfRegisterNoAccess: true,loading: false}
        default:
            return state

    }


};

const useValue = () => useThunkReducer(reducer, initialState);
export const {Provider, useTracked} = createContainer(useValue);

export const getConferenceList = () => async (dispatch, getState) => {
    dispatch({type: LOADING})
    const response = await fetch("/api/conference");
    const data = await response.json();
    dispatch({type: CONFERENCE_LIST, conferenceList: data})
}


export const getStreamInfo = () => async (dispatch, getState) => {
    dispatch({type: LOADING})
    const response = await fetch("/api/streaminfo");
    if (response.status !== 200) {
        dispatch(logout())

    } else {
        const data = await response.json();
        dispatch({type: STREAM_INFO, streamInfo: data})
    }

}


export const fetchLoggedIn = () => async (dispatch, getState) => {
    dispatch({type: LOADING})
    const response = await fetch("/api/login");
    const data = await response.json();
    dispatch({
        type: LOGIN,
        isLoggedIn: data.authenticated,
        pinVerified: data.verified_pin,
        passcodeVerified: data.verified_passcode,
        profileCompleted: data.profile_completed
    })
    dispatch({type: SELF_REGISTER_OPEN, selfRegisterOpen: data.self_register_open})
}

export const verifyPasscode = (passcode) => async (dispatch, getState) => {
    dispatch({type: LOADING})
    try {
        const response = await fetch("/api/passcode", {
            method: "POST",
            body: JSON.stringify(passcode)
        });

        console.log(response.status, response.statusText)

        if (response.status === 403) { // forbidden
            console.log("ELF")
            dispatch({type: SELF_REGISTER_NO_ACCESS})
            return
        }
        if (response.status === 401) { // wrong
            dispatch({type: VERIFIED_PASSCODE_RETRIES, retries: data})
            return
        }

        if (response.status !== 200) {
            throw new Error(response.status)
        }

        const data = await response.json();


        if (data === true) {
            console.log("PIN verified", data)
            dispatch({type: VERIFIED_PASSCODE, passcodeVerified: data, profileCompleted: true})
        } else {
            dispatch({type: VERIFIED_PASSCODE_RETRIES, retries: data})
        }
    } catch (e) {
        dispatch(logout())

    }
}

export const verifyPin = (pin) => async (dispatch, getState) => {
    try {
        dispatch({type: LOADING})

        const response = await fetch("/api/pin", {
            method: "POST",
            body: JSON.stringify(pin)
        });
        if (response.status !== 200) {
            throw new Error(response.status)
        }

        const data = await response.json();
        console.log("verifyPin returned", data)

        if (data === true) {
            dispatch({type: VERIFIED_PIN, pinVerified: data, profileCompleted: true})
        } else if (data === "self-register") {
            dispatch({type: VERIFIED_PIN, pinVerified: true, profileCompleted: false})
        } else {
            dispatch({type: VERIFIED_PIN_RETRIES, retries: data})
        }
    } catch (e) {
        dispatch(logout())

    }
}

export const setCurrentLanguage = (language) => async (dispatch,getState) => {
    if(language === "SE"){
        dispatch({type: SET_LANGUAGE, language: language})
    } else {
        dispatch({type: SET_LANGUAGE, language: "NO"})
    }



}


export const registerProfile = (name) => async (dispatch, getState) => {
    try {
        dispatch({type: LOADING})

        const response = await fetch("/api/register", {
            method: "POST",
            body: JSON.stringify(name)
        });

        if (response.status !== 200) {
            throw new Error(response.status)
        }

        const data = await response.json();

        if (data === true) {
            dispatch({type: REGISTER_COMPLETE, profileCompleted: true})
        } else {
            dispatch({type: REGISTER_FAILURE})
        }
    } catch (e) {
        dispatch(logout())

    }
}

export const login = (phoneNumber, conference) => async (dispatch, getState) => {
    dispatch({type: LOADING})
    const response = await fetch("/api/login", {
        method: "POST",
        body: JSON.stringify({
            "mobil": phoneNumber,
            "samlingshus": conference
        })
    });

    const data = await response.json();

    dispatch({
        type: LOGIN,
        isLoggedIn: data.authenticated,
        pinVerified: data.verified_pin,
        passcodeVerified: data.verified_passcode,
        profileCompleted: data.profile_completed
    })

    dispatch({type: SELF_REGISTER_OPEN, selfRegisterOpen: data.self_register_open})
}

export const logout = () => async (dispatch, getState) => {
    dispatch({type: LOADING})

    await fetch('api/login', {
        method: 'DELETE',
        credentials: 'include',
        headers: {'Content-Type': 'application/json'}
    })

    dispatch({type: LOGOUT})


}
export const forgetMe = () => async (dispatch, getState) => {
    dispatch({type: LOADING})

    await fetch('api/forgetme', {
        method: 'DELETE',
        credentials: 'include',
        headers: {'Content-Type': 'application/json'}
    })

    dispatch({type: FORGET_ME})


}
export const showUsageTerms = (show) => async (dispatch, getState)  => {

    dispatch({type: SHOW_USAGE_TERMS, showUsageTerms: show});
}
