import React from "react";

import {forgetMe, useTracked} from "../store";
import {Loader} from "./Loader";
import {Trans, useTranslation} from "react-i18next";


export function SelfRegisterClosed() {
    const { t } = useTranslation();
    const [state, dispatch] = useTracked();

    const handleLogout = () => {
        dispatch(forgetMe())
    }

    if (state.loading) {
        return <Loader/>
    }

    return (
        <>
            <Trans i18nKey="selfRegisterClosed"/>
            <button className={"primary"} href="#" onClick={() => handleLogout()}>{t('close')}</button>
        </>
    )

}
