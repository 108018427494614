import React, {Suspense} from "react";
import {Provider, useTracked} from './store'
import {Login} from "./components/Login";
import "./App.css"
import {VerifyPasscode} from "./components/VerifyPasscode";
import {VerifyPin} from "./components/VerifyPin";
import {Stream} from "./components/Stream";
import logo from "./logo.svg";
import {Register} from "./components/Register";
import './i18n';
import {Loader} from "./components/Loader";
import {RegisterFailure} from "./components/RegisterFailure";
import {CastProvider} from 'react-cast-sender';
import {UsageTerms} from "./components/UsageTerms";
import {SelfRegisterClosed} from "./components/SelfRegisterClosed";

const State = () => {

    const [state] = useTracked();
    console.log(state)
    if (state.registerFailure)
        return (<RegisterFailure/>)

    if(state.selfRegisterNoAccess)
        return <SelfRegisterClosed/>

    if (state.showUsageTerms)
        return (<UsageTerms/>)

    if (!state.isLoggedIn)
        return (<Login/>)



    if (state.isLoggedIn && !state.hasVerifiedPasscode)
        return (<VerifyPasscode/>)


    if (state.isLoggedIn && state.hasVerifiedPasscode && !state.hasVerifiedPin)
        return (<VerifyPin/>)

    if (state.isLoggedIn && state.hasVerifiedPasscode && state.hasVerifiedPin && !state.profileCompleted)
        return (<Register/>)

    return <Stream/>

}


const App = () => {
    const DEFAULT_MEDIA_ID = "CC1AD845"

    return (

        <Provider>
            <CastProvider receiverApplicationId={DEFAULT_MEDIA_ID} resumeSavedSession={true}>
                <div className="App">

                    <div className="wrapper">
                        <img src={logo} className="lll-logo" alt="logo"/>

                        <Suspense fallback={<Loader/>}>
                            <State/>
                        </Suspense>
                    </div>
                </div>
            </CastProvider>
        </Provider>

    )
}
export default App
