import React, {useEffect, useState} from "react";
import {Warning} from "./Warning";

import {forgetMe, logout, useTracked, verifyPasscode} from "../store";
import {Loader} from "./Loader";
import {useTranslation} from "react-i18next";


export function VerifyPasscode() {
    const { t } = useTranslation();
    const [state, dispatch] = useTracked();
    const [warning, setWarning] = useState([])


    const [passcode, setPasscode] = useState("")

    useEffect(() => {
        if (state.passcodeRetries === 0) {
            setWarning([])
            dispatch(logout())
        }

        if (state.passcodeRetries) {
            if (state.selfRegisterOpen) {
                setWarning([t('warningBadPasscode')]);
            } else {
                setWarning([t('warningNoSuchUser')])
            }

        }

        setPasscode("")
    }, [state.passcodeRetries,dispatch,t,state.selfRegisterOpen])


    const handlePasscode = () => {

        if (passcode === "") {
            setWarning([t('warningPasscodeRequired')])
        } else {
            dispatch(verifyPasscode(passcode))
        }


    }

    const handleLogout = () => {
        dispatch(forgetMe())
    }

    if (state.hasVerifiedPasscode || !state.isLoggedIn) {
        return null
    }

    if (state.loading) {
        return <Loader/>
    }


    return (
        <>
            <Warning message={warning}/>
            <p>{t('passcodeText')}</p>

            <input type="password" className={"passcode"} placeholder={t('passcodeInput')} value={passcode} id={"password"}
                   onChange={(e) => {
                       setPasscode(e.target.value)
                       setWarning([])
                   }}/>

            <button className="primary" onClick={() => handlePasscode()}>{t('passcodeButton')}</button>
            <button className="secondary" onClick={() => handleLogout()}>{t('passcodeCancelButton')}</button>
        </>
    )

}
