import React, {useEffect, useState} from "react";
import metadata from 'libphonenumber-js/metadata.mobile.json'
import {useTranslation} from "react-i18next";
import {AsYouType, parsePhoneNumber} from 'libphonenumber-js/mobile'
import {Warning} from "./Warning";
import ReactFlagsSelect from "react-flags-select";
import {fetchLoggedIn, getConferenceList, login, setCurrentLanguage, showUsageTerms, useTracked} from "../store";
import {Loader} from "./Loader";


export function Login() {
    const {t, i18n} = useTranslation();
    const [state, dispatch] = useTracked();
    const [countryCode, setCountryCode] = useState("NO")
    const [warning, setWarning] = useState([])
    const [phoneNumber, setPhoneNumber] = useState("")
    const [conference, setConference] = useState(null)
    const [gdpr, setGDPR] = useState(false)


    useEffect(() => {
        dispatch(fetchLoggedIn())
        dispatch(getConferenceList())
        let cc = navigator.language.split('-')
        if (cc[1]) {
            setCountryCode(cc[1])
            console.log("initial render")
        }
    },[dispatch])


    useEffect(()=>{

        console.log("sdadssad",countryCode)
        if (countryCode !== "NO" && countryCode !== "SE" && countryCode !== "DK") {
            setCountryCode("NO")
            console.log("sdadssad asddsaadsdasasd sa sad d as")
        }

        i18n.changeLanguage(countryCode)
        console.log("useEffect 2",countryCode)
    }, [countryCode, i18n])


    const handleLogin = () => {
        console.log("handleLogin", phoneNumber, countryCode, "conference", conference)

        if (phoneNumber === "" || conference == null) {
            setWarning([t('warningValidatePhoneAndConference')])
            return
        }


        if (gdpr === true) {
            let phone;
            try {
                phone = parsePhoneNumber(phoneNumber, countryCode, metadata)
                if (phone.isValid()) {
                    dispatch(login(phone.number.substr(1), conference))
                } else {
                    throw Error("Not valid")
                }
            } catch (e) {
                setWarning([t('warningPhoneNumber')])
            }


        } else {
            setWarning(t('warningGdprConsent'))
        }


    }

    const handlePhoneChange = event => {

        setWarning([])


        setPhoneNumber(new AsYouType(countryCode).input(event.target.value))
        try {

            let phone = parsePhoneNumber(phoneNumber, countryCode, metadata)
            console.log(countryCode, phone.country)
            if (phone.country && countryCode !== phone.country) {

                if (phone.country === "NO" || phone.country === "SE" || phone.country === "DK") {
                    console.log("updateflag")
                    setCountryCode(phone.country)
                    dispatch(setCurrentLanguage(phone.country))
                }

            }


        } catch (e) {

        }

    }

    const showUsageRights = ()=>{

        dispatch(showUsageTerms(true))
    }

    const handleGDPR = event => {
        setWarning([])
        setGDPR(event.currentTarget.checked)

    }

    const handleFlagSelect = (flag) => {

        setWarning([])
        setCountryCode(flag)
        console.log("new Country", countryCode, flag)
       // i18n.changeLanguage(flag)
      //  validatePhone(flag)


    }


    const handlePhoneBlur = event => {
        event.preventDefault()
        validatePhone(countryCode)
    }

    const validatePhone = (cc) => {
        if (phoneNumber === "") {
            return
        }

        try {
            console.log("countryCode", cc)
            let phone = parsePhoneNumber(phoneNumber, cc, metadata)

            console.log(phone.getType())
            if (phone.getType() !== "MOBILE") {
                setWarning(t('warningPhoneNumber'))
            }
        } catch (e) {
            console.log(e)
        }

    }


    if (state.isLoggedIn) {
        return null
    }

    if (state.loading) {
        return <Loader/>
    }


    return (
        <>
            <Warning message={warning}/>
            <div className="phoneInput">
                <ReactFlagsSelect
                                  countries={["NO", "SE", "DK"]}
                                  placeholder="Velg land" showSelectedLabel={false}
                                  showOptionLabel={false} selectedSize={24}
                                  selected={countryCode}
                                  optionsSize={24} onSelect={handleFlagSelect}/>

                <input type="tel" placeholder={t('phonenumber')} value={phoneNumber} id={"telefonnummer"}
                       onChange={handlePhoneChange} onBlur={handlePhoneBlur}/>
            </div>
            <select className="select-css" defaultValue={t('chooseConference')}
                    onChange={(e) => {
                        setConference(e.currentTarget.value)
                    }}>
                <option disabled>{t('chooseConference')}</option>
                {state.conferenceList.sort(((a, b) => a.konferanse > b.konferanse ? 1 : -1)).map((e) => (
                    <option key={e.msisdn} value={e.msisdn}>{e.konferanse}</option>))}
            </select>

            <label className={"container"}>{t('GdprConsent')}
                <input type={"checkbox"} value={"accept"} onChange={handleGDPR}/>
                <span className="checkmark"></span>
            </label>


            <button className="primary" onClick={handleLogin}>{t('loginButton')}
            </button>
            <div className={"bottom"}>
            <button className={"link-button"} onClick={showUsageRights}>Les vår personvernerklæring</button>
            </div>
        </>
    )

}
