import React from "react";
import {useTranslation} from "react-i18next";


export const AudioFailed = ({onConfirm,onClose}) => {

    const {t} = useTranslation();

    return (<div className={"yellowbox"}>
        <div className={"yellowbox__container"}>
            <div className={"yellowbox__sign"}>&#9888;</div>
            <div><span>{t('audioFailed')}</span></div>
            <div className={"bottom__container"}>
                <button onClick={()=>{onConfirm && onConfirm()}} className={"yellowbox__button"}>Ja</button>
                <button onClick={()=>{onClose && onClose()}} className={"yellowbox__button"}>Nei</button>
            </div>
        </div>
    </div>)


}
