import {useCallback, useEffect, useState} from "react";

export const AudioPlayer = ({onFailure, onPlayTimerUpdate, player}) => {

    const [timer] = useState(Date.now())

    const restartAudio = useCallback( () => {
        clearTimeout(window.timer)
        //console.log("onfaileur", window.timer)
        onFailure && onFailure()
    },[onFailure])

    const onProgress = useCallback((p) => {
        onPlayTimerUpdate && onPlayTimerUpdate(Date.now() - timer)
        //console.log("onPosition",window.timer,p)
        clearTimeout(window.timer)
        window.timer = setTimeout(restartAudio, 10000)
        //console.log("setting new timer",window.timer)

    },[onPlayTimerUpdate, restartAudio, timer])

if(player)
    player.ontimeupdate = onProgress
    //   audio.onsuspend = () => console.log("Script to be run when fetching the media data is stopped before it is completely loaded for whatever reason")

    // audio.onvolumechange = () => console.log("Script to be run each time the volume is changed which (includes setting the volume to")
    //audio.onwaiting= onPositition

    /*const audio = useMemo(()=> {
    return new Audio("/api/stream.mp3?" + Date.now())



    },[])
*/


    useEffect(() => {

        if(player){
        player.src = "/api/stream.mp3?" + Date.now()
        player.volume = 1
        player.load()
        player.play()


        player.onabort = () => console.log("onplay")
        player.oncanplay = () => console.log("Script to be run when a file is ready to start playing (when it has buffered enough to begin)")
        player.oncanplaythrough = () => console.log("Script to be run when a file can be played all the way to the end without pausing for buffering")
        player.oncuechange = () => console.log("Script to be run when the cue changes in a ")
        player.ondurationchange = () => console.log("Script to be run when the length of the media changes")
        player.onemptied = () => console.log("Script to be run when something bad happens and the file is suddenly unavailable (like unexpectedly disconnects)")
        player.onended = () => console.log("Script to be run when the media has reach the end (a useful event for messages like ")
        player.onerror = () => console.log("Script to be run when an error occurs when the file is being loaded")
        player.onloadeddata = () => console.log("Script to be run when media data is loaded")
        player.onloadedmetadata = () => console.log("Script to be run when meta data (like dimensions and duration) are loaded")
        player.onloadstart = () => console.log("Script to be run just as the file begins to load before anything is actually loaded")
        player.onpause = () => console.log("Script to be run when the media is paused either by the user or programmatically")
        player.onplay = () => console.log("Script to be run when the media is ready to start playing")
        player.onplaying = () => console.log("Script to be run when the media actually has started playing")
        player.onseeked = () => console.log("Script to be run when the seeking attribute is set to false indicating that seeking has ended")
        player.onseeking = () => console.log("Script to be run when the seeking attribute is set to true indicating that seeking is active")
        player.onstalled = () => console.log("Script to be run when the browser is unable to fetch the media data for whatever reason")


        player.onpause = () => {
            player.ontimeupdate = null
            //audio.src = null
        }
        }
        return () => {
            if(player)
                player.ontimeupdate = null
            clearTimeout(window.timer)
            console.log("cleaned up");
            if(player)
            player.pause()


            //  soundManager.destroySound('stream')


        };
    }, [ player]);



    return null
}
