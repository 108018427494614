import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

i18n
    .use(initReactI18next)
    .init({
        fallbackLng: 'NO',
        debug: true,

        resources: {
            NO: {
                translation: {
                    "phonenumber": "Mobilnummeret ditt",
                    "chooseConference": "Velg forsamling",
                    "GdprConsent": "Ja, jeg godtar at navn, telefonnummer og bruk logges",
                    "loginButton": "Lytt til overføring",
                    "passcodeText": "Du må oppgi koden for denne forsamlingen. Koden får du i din forsamling.",
                    "passcodeInput": "Passord",
                    "passcodeButton": "Bekreft",
                    "passcodeCancelButton": "Avslutt",
                    "smsText": "Vi har sendt deg en pinkode på tekstmelding som du må oppgi",
                    "registerText": "For å benytte tjenesten må du oppi fullt navn.",
                    "registerNameInput": "Fyll ut ditt navn",
                    "registerButton": "Bekreft",
                    "streamText": "Du får nå overføring fra",
                    "streamStartButton": "Start overføring",
                    "streamStopButton": "Stopp overføring",
                    "streamLogoutButton": "Logg meg ut",
                    "warningValidatePhoneAndConference": "Du må fylle inn mobilnummer og velge forsamling",
                    "warningGdprConsent": "For å bruke denne tjenesten må du godta at vi logger navn og hvordan tjenesten brukes.",
                    "warningPhoneNumber": "Nummeret ser ikke ut som et mobilnummer.",
                    "warningNameRequired": "Navn er påkrevd",
                    "warningPasscodeRequired": "Passord er påkrevd. Passordet får du hos din forsamling.",
                    "warningNoSuchUser": "Passordet er feil eller så har du ikke bruker i systemet",
                    "warningBadPasscode": "Passordet er feil",
                    "warningPinWrong": "PIN er feil. Har du fått tekstmelding?",
                    "registerAlreadyRegistered": "<p>Du er allerede registrert i en annen forsamling. Du kan bare være registrert som lytter i èn av forsamlingene.</p><p>Dersom du mener dette er feil må du ta kontakt med din lokale administrator for telefonoverføringer</p>",
                    "registerCancel": "Prøv på nytt",
                    "audioFailed": "Ser ut som forbindelsen ble brutt. Stemmer det?",
                    "close": "Lukk",
                    "selfRegisterClosed": "<p>Tilgang er stengt</p><p>Har du behov for tilgang må du ta kontakt med din lokale administrator for telefonoverføringer</p>",



                }
            },
            SE: {
                translation: {
                    "phonenumber": "Ditt mobilnummer",
                    "chooseConference": "Välj församling",
                    "GdprConsent": "Ja, jag godkänner personvernerklæringen og att namn och telefonnummer loggas",
                    "loginButton": "Lyssna på överföring",
                    "passcodeText": "Du behöver ange ett lösenord för denna församling. Lösenordet kan fås från församlingen.",
                    "passcode": "Lösenord",
                    "passcodeConfirm": "Bekräfta",
                    "passcodeCancelButton": "Stäng",
                    "smsText": "Vi har skickat en kod i ett textmeddelande som du behöver fylla i",
                    "registerText": "För att använda tjänsten behöver du ange ditt för- och efternamn.",
                    "registerNameInput": "Fyll i ditt för- och efternamn",
                    "registerButton": "Registrera mig",
                    "streamText": "Du får nu överföring från",
                    "streamStartButton": "Starta överföring",
                    "streamStopButton": "Stoppa överföring",
                    "streamLogoutButton": "Logga ut",
                    "warningValidatePhoneAndConference":"Du måste fylla i mobilnummer och välja församling",
                    "warningGdprConsent": "För att använda tjänstem måste du godkänna att vi loggar namn samt användning av tjänsten..",
                    "warningPhoneNumber": "Numret ser inte ut som ett mobilnummer.",
                    "warningNameRequired": "Du måste fylla i namn",
                    "warningPasscodeRequired": "Du måste fylla i ett lösenord. Lösenordet får du från din församling.",
                    "warningBadPasscode": "Fel lösenord",
                    "warningPinWrong": "Fel kod. Har du fått ett textmeddelande?",
                    "registerAlreadyRegistered": "<p>Du är redan registrerad i en annan församling. Du kan bara vara lyssnare i en av församlingarna.</p><p>Om detta är fel, kontakta din lokala telefonöverföringsadministratör</p>",
                    "registerCancel": "Försök igen",
                    "audioFailed": "Det verkar som att anslutningen bröts. Är det rätt?",
                    "close": "Stäng",
                    "selfRegisterClosed": "<p>Tillgången är stängd</p><p>Om du behöver tillgång, kontakta din lokala telefonöverföringsadministratör</p>",
                }
            }
        },
        lng: "NO",

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        }
    });


export default i18n;
